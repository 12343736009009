import type { EventMatch } from '@salvatore0193/definitions/lib/Matches';
import { EventType } from '@salvatore0193/definitions/lib/Matches/const';
import { formatDate } from '~/utils';
import { EventMatchType } from '@salvatore0193/definitions/lib/Matches';

export function resolveOppSendDate(events: Partial<EventMatch>[]): string {
  if (events.length === 0) return '-';

  function isEventPublishedToCompanyDash(
    events: Partial<EventMatch>[]
  ): boolean {
    return !!events.find(
      (e) =>
        e.type?.toLowerCase() ===
        EventType.PUBLISHED_TO_COMPANY_DASHBOARD.toLowerCase()
    );
  }

  if (isEventPublishedToCompanyDash(events)) {
    const date = events.find(
      (e) =>
        e.type?.toLowerCase() ===
        EventType.PUBLISHED_TO_COMPANY_DASHBOARD.toLowerCase()
    )?.date;

    // @ts-ignore
    return date?.$date?.$numberLong
      ? // @ts-ignore
        formatDate(date.$date.$numberLong, true)
      : date
      ? formatDate(date, false)
      : '-';
  }

  return '-';
}

export function resolveEventType(eventType: EventMatchType): string {
  if (!eventType) return '-';

  if (eventType.toLowerCase() === EventType.REJECTED_BY_COMPANY.toLowerCase())
    return 'Rejected';

  return eventType.toString();
}
